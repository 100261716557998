import { createSlice } from '@reduxjs/toolkit';
import { UserType } from 'types';

type initialStateType = {
  token: string;
  user: UserType;
  isLoggedIn: boolean;
  loading: boolean;
  favoriteStatus: boolean;
};

const initialUser: UserType = {
  _id: '',
  email: '',
  name: '',
  password: '',
  avatar: '',
  mobile: '',
  ip: '',
  country: '',
  level: 0,
  currency: '',
  address: '',
  bonus: 0,
  status: true,
};

const initialState: initialStateType = {
  token: '',
  user: initialUser,
  isLoggedIn: false,
  loading: false,
  favoriteStatus: false,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.loading = false;
    },

    edit(state, action) {
      state.user = action.payload;
    },

    setFavorite(state, action) {
      state.favoriteStatus = action.payload;
    },

    signout(state) {
      state.token = '';
      state.user = initialUser;
      state.isLoggedIn = false;
    },
  },
});

export default auth.reducer;

export const { signin, signout, edit, setFavorite } = auth.actions;
